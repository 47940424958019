"use strict";
/*
  Formats the the output from the popup so that the quill editor can properly display it
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutputHTMLParser = OutputHTMLParser;
exports.ConvertMultipleSpacesToSingle = ConvertMultipleSpacesToSingle;
exports.PreserveNewlinesBr = PreserveNewlinesBr;
exports.PreserveNewlinesPTags = PreserveNewlinesPTags;
exports.FixTagSpaceOpenTag = FixTagSpaceOpenTag;
exports.FixTagSpaceCloseTag = FixTagSpaceCloseTag;
exports.Compose = Compose;
function OutputHTMLParser(inputHtmlFromQuillPopup) {
    return Compose([
        ConvertMultipleSpacesToSingle,
        FixTagSpaceOpenTag,
        FixTagSpaceCloseTag,
        PreserveNewlinesBr,
        PreserveNewlinesPTags,
    ], inputHtmlFromQuillPopup);
}
function ConvertMultipleSpacesToSingle(input) {
    return input.replace(/\s+/g, " ").trim();
}
function PreserveNewlinesBr(input) {
    return input.replace(/<br([\s]*[\/]?>)/g, "<p> </p>");
}
function PreserveNewlinesPTags(input) {
    return input.replace(/<p><\/p>/g, "<p> </p>");
}
function FixTagSpaceOpenTag(input) {
    // Open tag remove space on inside
    return input.replace(/(<(?!\/)[\w=\."'\s]*>) /g, "$1");
}
function FixTagSpaceCloseTag(input) {
    // Close tag remove space on inside
    return input.replace(/ (<\/[\w]+>)/g, "$1");
}
function Compose(functions, input) {
    return functions.reduce(function (acc, cur) { return cur(acc); }, input);
}
