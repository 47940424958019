"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormatHTMLStringIndentation = FormatHTMLStringIndentation;
// Adapted FROM jsfiddle here: https://jsfiddle.net/buksy/rxucg1gd/
function FormatHTMLStringIndentation(code, logger) {
    "use strict";
    var stripWhiteSpaces = true;
    var stripEmptyLines = true;
    var whitespace = " ".repeat(2); // Default indenting 4 whitespaces
    var currentIndent = 0;
    var newlineChar = "\n";
    var prevChar = null;
    var char = null;
    var nextChar = null;
    var result = "";
    for (var pos = 0; pos <= code.length; pos++) {
        prevChar = char;
        char = code.substr(pos, 1);
        nextChar = code.substr(pos + 1, 1);
        var isBrTag = code.substr(pos, 4) === "<br>";
        var isOpeningTag = char === "<" && nextChar !== "/" && !isBrTag;
        var isClosingTag = char === "<" && nextChar === "/" && !isBrTag;
        var isTagEnd = prevChar === ">" && char !== "<" && currentIndent > 0;
        var isTagNext = !isBrTag &&
            !isOpeningTag &&
            !isClosingTag &&
            isTagEnd &&
            code.substr(pos, code.substr(pos).indexOf("<")).trim() === "";
        if (isBrTag) {
            // If opening tag, add newline character and indention
            result += newlineChar;
            currentIndent--;
            pos += 4;
        }
        if (isOpeningTag) {
            // If opening tag, add newline character and indention
            result += newlineChar + whitespace.repeat(currentIndent);
            currentIndent++;
        }
        // if Closing tag, add newline and indention
        else if (isClosingTag) {
            // If there're more closing tags than opening
            if (--currentIndent < 0)
                currentIndent = 0;
            result += newlineChar + whitespace.repeat(currentIndent);
        }
        // remove multiple whitespaces
        else if (stripWhiteSpaces === true && char === " " && nextChar === " ")
            char = "";
        // remove empty lines
        else if (stripEmptyLines === true && char === newlineChar) {
            //debugger;
            if (code.substr(pos, code.substr(pos).indexOf("<")).trim() === "")
                char = "";
        }
        if (isTagEnd && !isTagNext) {
            result += newlineChar + whitespace.repeat(currentIndent);
        }
        result += char;
    }
    logger.log("formatHTML", {
        before: code,
        after: result,
    });
    return result;
}
